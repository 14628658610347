.intro {
  background-color: rgb(255, 255, 255);
  position: relative;
}

.intro h1 {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 13%;
  font-size: 2.1rem;
  color: grey;
  font-weight: normal;
}
.intro p {
  position: absolute;
  bottom: 7%;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: rgb(156, 156, 156);
}

.profile_img {
  z-index: 2;
  width: 19%;
  border-radius: 100%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.intro-btn {
  position: relative;
  width: 30%;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.568);
  padding: 1rem;
  font-size: 1.3rem;
  transition: 0.2s ease-in;
  color: rgba(95, 95, 95, 0.61);
}

@media only screen and (min-width: 500px) {
  .profile_img {
    width: 90px;
  }
  .btn-project {
    bottom: 57% !important;
  }

  .btn-about {
    bottom: 57% !important;
  }

  .btn-skill {
    top: 43% !important;
  }
  .btn-work {
    top: 43% !important;
  }
  .profile_img {
    top: 43%;
  }
  .hr-line {
    top: 43% !important;
  }
  .vt-line {
    top: 43% !important;
  }
}

/* 4 Buttons */
.btn-project {
  border-radius: 100% 0 0 0;
  position: absolute;
  bottom: 50%;
  right: 50%;
}

.btn-about {
  border-radius: 0 100% 0 0;
  position: absolute;
  bottom: 50%;
  left: 50%;
}

.btn-skill {
  border-radius: 0 0 0 100%;
  position: absolute;
  top: 50%;
  right: 50%;
}
.btn-work {
  border-radius: 0 0 100% 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.intro-text {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.hr-line {
  position: absolute;
  border-bottom: 1px solid rgba(119, 119, 119, 0.192);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.vt-line {
  position: absolute;
  border-left: 1px solid rgba(119, 119, 119, 0.192);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.on {
  color: white;
}

.on .intro-text {
  display: none;
}
.on i {
  position: absolute;
  font-size: 2rem;
}
.project_on {
  background: radial-gradient(
    circle at bottom right,
    rgba(177, 46, 203, 1) 0%,
    rgba(140, 46, 188, 1) 29%,
    rgba(90, 20, 143, 1) 100%
  );
  box-shadow: -10px -10px 15px rgba(90, 20, 143, 0.5);
}
.about_on {
  background: radial-gradient(
    circle at bottom left,
    rgba(134, 214, 98, 1) 0%,
    rgba(106, 212, 69, 1) 29%,
    rgba(98, 186, 71, 1) 100%
  );
  box-shadow: 10px -10px 15px rgba(98, 186, 71, 0.5);
}

.skill_on {
  background: radial-gradient(
    circle at top right,
    rgba(222, 50, 154, 1) 0%,
    rgba(234, 53, 117, 1) 29%,
    rgba(233, 51, 82, 1) 100%
  );
  box-shadow: -10px 10px 15px 0 rgba(233, 51, 82, 0.5);
}

.work_on {
  background: radial-gradient(
    circle at top left,
    rgba(26, 212, 244, 1) 0%,
    rgba(15, 152, 253, 1) 25%,
    rgba(7, 64, 222, 1) 100%
  );
  box-shadow: 10px 10px 15px 0 rgba(7, 64, 222, 0.5);
}

.project_on i {
  right: 30%;
  bottom: 30%;
}
.about_on i {
  left: 33%;
  bottom: 33%;
}
.skill_on i {
  right: 35%;
  top: 35%;
}
.work_on i {
  left: 35%;
  top: 35%;
}
