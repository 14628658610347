body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
}

a {
  text-decoration: none;
  color: #17a2b8;
}

p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}
.container {
  padding: 0 12%;
}

.text-center {
  text-align: center;
}

hr {
  margin: 1rem;
  opacity: 0.7;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mt-1 {
  margin-top: 2rem;
}

.section-title {
  padding-top: 1.7rem;
  font-size: 1.7rem;
  margin-left: 12%;
  font-weight: bold;
}

br {
  margin-top: 1rem;
}
