.skills-title {
  color: rgba(233, 51, 82, 1);
}

.skills-text {
  padding: 1rem;
}

.skill-title {
  color: rgb(87, 87, 87);
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.skills-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 3.7rem);
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 4rem;
}

.skill-badge {
  height: 5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-badge i {
  font-size: 3rem;
}

.skill-badge svg {
  font-size: 3rem;
}
.skill-badge-name {
  position: absolute;
  bottom: 5%;
  font-size: 1rem;
  margin-top: 0.1rem;
  color: rgba(95, 95, 95, 0.897);
}

@media only screen and (min-width: 600px) {
  .skills-title {
    text-align: center;
    margin: 0;
    font-size: 2.1rem;
    margin-bottom: 2rem;
  }
  .skill-title {
    text-align: left;
    font-size: 1.7rem;
  }
  .skill-badge {
    height: 6rem;
  }
  .skill-badge svg,
  .skill-badge i {
    font-size: 3.2rem;
  }
  .skill-badge-name {
    font-size: 1.1rem;
  }

  .skills-list {
    width: 600px;
    grid-template-columns: repeat(auto-fit, 4.3rem);
    margin-bottom: 3rem;
  }
  .skills-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
