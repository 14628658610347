.footer {
  position: relative;
  margin-top: 3rem;
  padding: 3rem;
  line-height: 2rem;
}

.footer-title {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 1.3rem;
}

.footer-links {
  display: flex;
  font-size: 2rem;
  margin-top: 2.5rem;
  justify-content: space-evenly;
}

.footer-links a {
  color: rgb(255, 255, 255);
}

.footer-links a:hover {
  opacity: 0.7;
}
.footer p {
  color: white;
  text-align: center;
}

.copyright {
  margin-top: 2.5rem;
  font-size: 0.8rem;
}
.color-About {
  background: rgba(98, 186, 71, 1);
}
.color-Projects {
  background: rgba(90, 20, 143, 1);
}
.color-Work {
  background: rgba(7, 64, 222, 1);
}
.color-Skills {
  background: rgba(233, 51, 82, 1);
}
