.about-title {
  color: rgba(98, 186, 71, 1);
}

.about-text {
  padding: 0.5rem 0;
  font-size: 0.9rem;
  line-height: 1.4rem;
  display: flex;
  flex-direction: column;
}

.about-text-title {
  margin-top: 1rem;
  font-size: 1.2rem;
  text-align: center;
  color: grey;
}
.about-text-lead {
  color: rgb(156, 156, 156);
  font-size: 0.9rem;
  text-align: center;
}

.about-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-list i {
  color: rgba(85, 85, 85, 0.596);
}

@media only screen and (min-width: 600px) {
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-title {
    text-align: center;
    margin: 0;
    padding-top: 2rem;
    font-size: 2.1rem;
  }
  .about-text {
    width: 600px;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: center;
    align-items: center;
  }
  .about-text p {
    width: 80%;
  }
}
