.work-title {
  color: rgba(7, 64, 222, 1);
}

.work-text {
  padding: 0.5rem 0;
  font-size: 0.9rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.work-text-title {
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

.work-text-date {
  margin-top: 1rem;
  color: grey;
  text-align: center;
}

.work-text-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  line-height: 1.5rem;
}

@media only screen and (min-width: 600px) {
  .work-title {
    text-align: center;
    margin: 0;
    font-size: 2.1rem;
  }
  .work-text-title {
    font-size: 1.7rem;
  }
  .work-text-date {
    font-size: 1.2rem;
  }
  .work-text-list {
    font-size: 1.1rem;
    line-height: 1.5rem;
    width: 600px;
  }
}
